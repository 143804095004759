body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.plan_dates .MuiInputBase-input {
  padding: 9.5px 14px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --primary-color: #4ad7d1;
}
.h-40px {
  height: 40px !important;
}
.h-100 {
  height: 100% !important;
}
.h-100px {
  height: 100px !important;
}
.w-100 {
  width: 100% !important;
}
.btn_cta {
  padding: 12px 24px !important;
  background: linear-gradient(90deg, #68c1d2 0%, #2bd2b6 99.99%) !important;
  border-radius: 8px !important;
  font-family: "Nunito" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: center !important;
  letter-spacing: 0.08em !important;
  color: #ffffff !important;
  cursor: pointer;
}
