.plan_table {
  width: 100%;
  border-collapse: collapse;
}

.plan_table th,
.plan_table td {
  padding: 10px;
}

.plan_table thead th {
  width: 300px;
  color: white;
  background-color: gray;
  font-size: 18px;
}

.plan_tbody_logo img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.274);
}
.gd_explore_main_results_root {
  margin-top: 106px;
}
.gd_explore_main_results_block {
  position: relative;
  /* height: 55vh; */
  background-color: aliceblue;
}
.gd_explore_main_results_block_inputs {
  position: absolute;
  /* height: 55vh; */
  background-color: aliceblue;
}
.gd_explore_main_results {
  position: absolute;
  top: 0;
  /* height: 90%; */
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.gd_explore_main_results_main {
  border: 1px solid #cacaca;
  border-radius: 16px;
  /* max-height: 58vh; */
  /* height: 58vh; */
  /* overflow: hidden;
  overflow-y: auto; */
  /* position: relative; */
}

.gd_explore_main_results_head {
  padding: 20px 24px 16px 24px;
}
.body1 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.caption {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.scrollable-cell {
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}
