.loader_root {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
/* .MuiAvatar-root img{
  object-fit: cover;
} */
.row_start {
  align-items: flex-start;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.email {
  text-transform: none !important;
}
.loader_root img {
  height: 200px;
}

.bold {
  font-weight: 600;
}
.between {
  justify-content: space-between;
  display: flex;
}
.center {
  justify-content: center;
  display: flex;
  align-items: center;
}
h3 {
  color: #000000;
}
.width_fit {
  width: fit-content;
}
.pl-60 {
  padding-left: 60px !important;
}
.pr-20 {
  padding-right: 20px;
}
.aqua {
  color: #4ad7d1 !important;
}
.aqua2 {
  color: #239f99 !important;
}
.f500 {
  font-weight: 500;
}

.black {
  color: black !important;
}
.nunito {
  font-family: "Nunito" !important;
  font-display: swap;
}
input {
  font-family: "Nunito", sans-serif;
  font-display: swap;
  font-weight: 100;
  padding: 3px 0;
}
select {
  font-family: "Nunito", sans-serif;
  font-display: swap;
  font-weight: 100;
  padding: 3px 0;
}
input:focus {
  outline: 0.5px solid white;
}
.input_stylish_file {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  width: 0;
  height: 0;
}
.pos_abs {
  position: absolute;
}
.pos_rel {
  position: relative;
}
::placeholder {
  padding: 2px 0;
  color: white;
}
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}
.cursor_pointer {
  cursor: pointer;
}
.transparent_CTA {
  border: none;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  font-family: Nunito;
  font-display: swap;
  padding: 15px 20px;
  margin-top: 10px;
  color: #4ad7d1;
  background: transparent;
}

.transparent_CTA:hover {
  color: white;
}
.small_CTA_btn {
  background: linear-gradient(90deg, #68c1d2, #2bd2b6 99.99%);
  border-radius: 4px;
  color: #fff;
  border: none;
  outline: none;
  padding: 7px 16px;
  font-size: 12px;
}
.grey_CTA {
  background: #404654 !important;
}
.grey_CTA:hover {
  color: #f2f2f2 !important;
}
.outline_CTA {
  border: none;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  font-family: Nunito;
  font-display: swap;
  padding: 12px 20px;
  margin-top: 10px;
  color: #ffffff;
  border: 2px solid #ffffff;
  background: transparent;
}

.outline_CTA:hover {
  color: black;
  /* border: none; */
  background: white;
}
.black_ouline_CTA {
  border: none;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  font-family: Nunito;
  font-display: swap;
  padding: 12px 20px;
  margin-top: 10px;
  color: #000;
  border: 2px solid #000;
  background: transparent;
}
.relative {
  position: relative;
}
.green {
  color: #00915b;
}
.cyan_ouline_CTA {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  font-family: Nunito;
  font-display: swap;
  padding: 12px 20px;
  margin-top: 10px;
  color: #fff;
  border: 3px solid #239f99;
  background: transparent;
}

.no-margin {
  margin: 0 !important;
}
.min_h55 {
  min-height: 55px;
}

/** @format */
.no-padding {
  padding: 0px !important;
}

.no-left-padding {
  padding-left: 0;
}

.no-right-padding {
  padding-right: 0;
}

.margin-150 {
  margin: 150px 0px;
}

.margin-100 {
  margin: 100px 0px;
}

/* ----------padding css---------- */
.pya-1 {
  padding: 8px 0;
}

.pba-1 {
  padding-bottom: 1rem;
}

.pa-0 {
  padding: 0.5rem !important;
}

/* ----------margin css----------- */
.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mba-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mta-2 {
  margin-top: 2rem !important;
}

.mta-5 {
  margin-top: 5rem !important;
}
.mta-12 {
  margin-top: 12rem !important;
}
.height-100vh {
  height: 100vh;
}
.mh-80 {
  min-height: 80vh;
}
.mt-24 {
  margin-top: 24px;
}

.colorwhite {
  color: white;
}
.align-center {
  display: flex;
  flex-direction: row;
  height: 50vh;
  align-items: center;
  justify-content: center;
}
.align_center {
  align-items: center;
}
.Service-container {
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0px;
}

.shareChip {
  font-size: 12px;
  padding: 2px;
  margin-left: 5px;
  border-radius: 5px;
  background-color: #f2f2f2;
}
.p_chip2 {
  font-size: 12px;
  margin: 3px 0;
  padding: 8px 12px;
  border-radius: 15px;
  background: #4ad7d126;
  width: fit-content;
  margin-right: 12px;
  color: #000;
  text-transform: capitalize;
}
.p_chip {
  font-size: 13px;
  margin-bottom: 5px;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: rgba(202, 202, 202, 0.3);
  width: fit-content;
  margin-right: 5px;
}

.v_center {
  display: flex;
  height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hidden {
  display: none;
}
.font10 {
  font-size: 10px !important;
}
.font11 {
  font-size: 11px !important;
}
.font13 {
  font-size: 13px;
}
.font12 {
  font-size: 12px !important;
}
.font14 {
  font-size: 14px !important;
}
.font16 {
  font-size: 16px !important;
}
.font18 {
  font-size: 18px !important;
}
.font15 {
  font-size: 15px !important;
}
.font20 {
  font-size: 20px !important;
}
.font40 {
  font-size: 40px !important;
}
p {
  font-family: "Nunito";
  font-display: swap;
  line-height: 1.15;
}
.lh1_5 {
  line-height: 1.5;
}
.lh2 {
  line-height: 2;
}
.br {
  border-right: 2px solid #cacaca;
}

h1 h2 h3 h4 {
  font-family: "AgusSans";
  font-display: swap;
}

.font-nunito {
  font-family: "Nunito";
  font-display: swap;
}

.h1_white_75 {
  font-family: "Poppins";
  font-display: swap;
  font-size: 75px;
  color: white;
}

.popping_ff {
  font-family: "Poppins";
  font-display: swap;
}

.h2_white_40 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 40px;
  color: white;
}
.h2_white_50 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 50px;
  color: white;
}

.h2_white_30 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 30px;
  color: white;
}

.h2_white_35 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 35px;
  color: white;
}

.h_white_20 {
  font-size: 20px;
  color: white;
  font-family: "Nunito" !important;
  font-display: swap;
}

.h1_black_35 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 35px;
  color: #fff;
}

.h1_black_30 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 30px;
  color: #000;
}
.h1_black_30_1 {
  font-family: "Nunito";
  font-display: swap;
  font-size: 30px;
  color: #000;
  font-weight: 300;
  margin: 0;
}

.h1_white_30_1 {
  font-family: "Nunito";
  font-display: swap;
  font-size: 30px;
  color: #fff;
  font-weight: 300;
  margin: 0;
}

.h2_black_30 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 30px;
  color: black;
}

.h3_white_30 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 30px;
  color: #fff;
}
.h3_white_25 {
  font-size: 25px;
  letter-spacing: 1px;
  font-family: AgusSans;
  font-display: swap;
}
.h3_white_20 {
  font-size: 20px;
  letter-spacing: 1px;
  font-family: AgusSans;
  font-display: swap;
  color: #fff;
}
.h3_grey_20 {
  font-size: 20px;
  letter-spacing: 1px;
  font-family: AgusSans;
  font-display: swap;
  color: #9f9f9f;
}
.h2_black_40 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 40px;
  color: #000;
}

.h3_white_45 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 30px;
  color: #fff;
}

.p_small_grey {
  color: #757575;
  font-size: 15px;
}
.text_capi {
  text-transform: capitalize !important;
}
.transform_none {
  text-transform: none !important;
}
.p_small_black_bold {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  font-family: "Nunito";
  font-display: swap;
}
.p_small_black_title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  font-family: "Nunito";
  font-display: swap;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.p_small_blue_bold {
  color: #404654;
  font-size: 15px;
  font-weight: 600;
  font-family: "Nunito";
  font-display: swap;
}
.p_small_blue {
  color: #404654;
  font-size: 15px;
  font-family: "Nunito";
  font-display: swap;
}
.p_small_black_caption {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: "Nunito";
  font-display: swap;
}
.p_small_black_10 {
  color: #000;
  font-size: 10px;
}
.p_small_black_13 {
  color: #000;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.02em;
}
.p_small_grey_13 {
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.02em;
}
.p_small_grey_bold {
  color: #757575;
  font-size: 15px;
  font-family: "Nunito";
  font-display: swap;
  font-weight: 600;
}
.grey_75 {
  color: #757575;
}
.grey_69 {
  color: #696969;
}
.blue_23 {
  color: #239f99;
}
.blue {
  color: blue;
}
.primary {
  background-color: rgb(20, 84, 202);
}
.warning {
  background-color: rgb(255, 132, 0);
}
.secondary {
  background-color: rgb(138, 0, 244);
}
a {
  color: inherit;
}
a:hover {
  color: inherit;
}
a svg {
  color: inherit;
}
.p_small_grey2 {
  color: #757575;
  font-size: 15px;
}
.p_small_white {
  color: #fff;
  font-family: "Nunito";
  font-display: swap;
  font-size: 15px;
}
.p_small_whiteGray {
  color: #9f9f9f;
  font-size: 15px;
}
.p_very_small_whiteGray {
  color: #9f9f9f;
  font-size: 0.7rem;
}
.p_small_whiteGray2 {
  color: #9f9f9f;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
.p_just_small_darkGrey {
  color: #404654;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.02em;
  margin: 0;
  font-family: "Nunito";
  font-display: swap;
}
.p_just_small_darkGrey14 {
  color: #404654;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.02em;
  margin: 0;
  font-family: "Nunito";
  font-display: swap;
}
.p_just_medium_darkGrey16 {
  color: #404654;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin: 0;
  font-family: "Nunito";
  font-display: swap;
}
.p_just_small_lightGrey {
  color: #757575;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.08em;
  margin: 0;
  font-family: "Nunito";
  font-display: swap;
}
.p_just_midsmall_black14 {
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000000;
}
.p_just_midsmall_grey14 {
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.75);
}
.p_just_small_darkgrey12 {
  color: #404654;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.02em;
  margin: 0;
  font-family: "Nunito";
  font-display: swap;
}
.p_just_small_black12 {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.02em;
  margin: 0;
  font-family: "Nunito";
  font-display: swap;
}
.p_midium_black18 {
  margin: 0;
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #000000;
}
.justify_btwn {
  justify-content: space-between;
}
.aqua_bg_liner {
  background: linear-gradient(
    90deg,
    rgba(104, 193, 210, 1) 0%,
    rgba(43, 210, 182, 1) 100%
  );
}

.p_medium_white {
  color: #fff;
  font-family: "Nunito";
  font-display: swap;
  font-size: 20px;
  font-weight: 300;
}
.p_medium_black {
  color: #000;
  font-family: "Nunito";
  font-display: swap;
  font-size: 20px;
  font-weight: 300;
}
.word_break {
  word-break: break-all;
}

.h5_medium_white {
  font-family: "Nunito";
  font-display: swap;
  color: #fff;
  font-size: 20px;
}
.h5_heading_poppins {
  font-family: Poppins;
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #000000;
}

.h5_medium_black {
  font-family: "Nunito";
  font-display: swap;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}
.h5_midlarge_black24 {
  color: #000;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 28px;
}

.h5_small_black {
  font-family: "Nunito";
  font-display: swap;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
}
.h6_black {
  font-family: AgusSans;
  font-display: swap;
  font-size: 15px;
  color: black;
}
.h6_white {
  font-family: AgusSans;
  font-display: swap;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
}
.h6_grey_15 {
  font-family: AgusSans;
  font-display: swap;
  font-size: 15px;
  color: #9f9f9f;
  text-transform: uppercase;
}

.width_auto {
  width: auto;
}

hr {
  background-color: #9f9f9f;
}

.w_100 {
  width: 100%;
}
.w_80 {
  width: 80%;
}

.w_70 {
  width: 70%;
}

.w_75 {
  width: 75%;
}

.w_60 {
  width: 60%;
}

.w_49 {
  width: 49%;
}
.w_40 {
  width: 40%;
}

.w_46 {
  width: 46%;
}

.w_42 {
  width: 42%;
}

.w_33 {
  width: 33%;
}

.mt-8 {
  margin-top: 8px !important;
}
.mt-16 {
  margin-top: 16px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mh-200 {
  min-height: 200px;
}
.mh-150 {
  min-height: 150px;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

.jus_con_center {
  justify-content: center;
}

.jus_con_space_around {
  justify-content: space-around;
}
.jus_con_space_bet {
  justify-content: space-between;
}
.gen_input {
  border-radius: 5px;
  font-family: "Nunito";
  font-display: swap;
  padding: 5px;
  height: 35px;
}
.gen_textarea {
  height: 60px;
}

.text_align_start {
  text-align: start;
}

.text_align_center {
  text-align: center;
}

.letter_spacing_1_2 {
  letter-spacing: 1.2px;
}

.line_height_4 {
  line-height: 0.4;
}
.MuiSnackbar-root {
  background-color: #ffffff;
  border-radius: 5px;
}
.MuiSnackbarContent-message {
  color: #000;
  font-family: "Nunito";
  font-display: swap;
  font-weight: 600;
}
.p_button {
  cursor: pointer;
  width: auto;
}

.transparent_btn_noBorder {
  width: fit-content;
  border: none;
  outline: none;
  background-color: transparent;
}
.viewAll_anchor {
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #2bd2b6;
}

.fw-1 {
  font-weight: 100;
}
.fw-2 {
  font-weight: 200;
}
.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-b {
  font-weight: bold;
}
.fw-exb {
  font-weight: bolder;
}
.mx-100 {
  max-width: 100%;
}
.br-12 {
  border-radius: 12px !important;
}
.thin_bg {
  border: 0.5px solid rgba(202, 202, 202, 0.5);
  border-radius: 16px !important;
}
.aquaDot {
  fill: #4ad7d1;
  transform: scale(1.8);
  margin-top: -1px;
}
.MuiSkeleton-root {
  border-radius: 6px;
}
.skeleton_bg {
  background-color: #fff;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 15px;
}
.dashboard01_tabpanel,
.dashboard01_tabpanel_root {
  width: 100%;
}
.box_10 {
  width: 10px !important;
  height: 10px !important;
}
.box_20 {
  width: 20px !important;
  height: 20px !important;
}
.w-40 {
  width: 40% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40px {
  width: 40px !important;
}
.greenBorder {
  border: 3px solid green;
}
.redborder {
  border: 3px solid red;
}
.red {
  color: red;
}
.purpleBorder {
  border: 3px solid purple;
}
.yellowBorder {
  border: 3px solid yellow;
}
.blueBorder {
  border: 3px solid blue;
}
.widthFit {
  width: fit-content;
}

@media (max-width: 1150px) {
}

@media (max-width: 992px) {
  .h1_white_75 {
    font-size: 70px;
  }
}

@media (max-width: 768px) {
  .h1_white_75 {
    font-size: 60px;
  }
}

@media (max-width: 600px) {
  .h1_white_75 {
    font-size: 40px;
  }
  .h2_black_40 {
    font-size: 25px;
  }
  .h2_white_40 {
    font-size: 25px;
  }
  .w_70 {
    width: 100%;
  }
}
