.white_navbar {
  background-color: white;
}

.navbar_logo {
  width: 120px;
}
.signup_btn{
  background: linear-gradient(
    90deg,
    rgba(104, 193, 210, 1) 0%,
    rgba(43, 210, 182, 1) 100%
  );
  color: #fff !important;
  border-radius: 5px;
}

.nav-link {
  color: #9f9f9f;
  font-family: "AgusSans";
  font-size: 16px;
  padding: 5px 20px !important;
}
.nav-link:hover {
  color: #fff;
}
.active {
  color: #ffffff !important;
}
.navbar-nav {
  align-items: center;
}
.z-index{
  z-index:100 !important ;
}
.navbar_root .MuiSvgIcon-root{
  color: #fff;
}
/* .navbar img , .dashboard_nav img{
  object-fit: contain !important;
} */

.navbar_container {
  margin: 0 6rem;
}
.navbar-nav .MuiPaper-root {
  left: 15px !important;
  width: 100%;
  background-color: #000000 !important;
  color: #fff !important;
}
.navbar_root .MuiMenuItem-root,
.MuiMenuItem-gutters {
  font-family: "Nunito" !important;
  margin: 5px 20px !important;
  justify-content: left;
}
.navbar_root .MuiList-root {
  display: grid;
}

.navbar_root .MuiBadge-badge {
  background-color: #2bd2b6;
}

.p_agus_sans_16{
  font-family: AgusSans !important;
  font-size: 16px !important;
  color:#9f9f9f !important;
}
.color_white{
  color: #ffffff !important; 
 }
.p_agus_sans_16:hover{
  color:#ffffff !important;
}

@media (max-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row !important;
  }
  .nav-item {
    padding: 0 8px;
  }
  .nav-link {
    padding: 0 14px !important;
  }
}
@media (max-width: 768px) {
  .navbar_container {
    margin: 0 4rem !important;
  }
}
@media (max-width: 600px) {
  .navbar_container {
    margin: 0 1rem !important;
  }
}
@media (max-width: 1150px) {
  .navbar_container {
    margin: 0 5rem;
  }
}
