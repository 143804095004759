.verify-investor-head-img {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.verify-investor-head-img figure {
  width: 100px;
  border-radius: 50%;
  border: 1px solid;
  padding: 4px;
  margin-bottom: 4px;
}
.verify-investor-head-img figure img {
  border-radius: 50%;
}
.verify-investor-head-img figcaption {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
p.verify-investor-name {
  font-size: 20px;
  font-weight: bolder;
}
.verify-founder-entities {
  width: 57%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.verify-investor-details p {
  margin: 3px;
}

.verify-investor-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.previousInvestment p,
.intrestedin p {
  margin: 0;
  font-size: 17px;
}
.verify-investor-main-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.all-main-details {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.verify-investor-photoId {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.verify-investor-photoId figure {
  width: 55%;
}
.verify-investor-imgicon {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}
.verify-investor-imgicon p {
  font-weight: bolder;
  font-size: 17px;
  margin-left: 5px;
  margin-bottom: 3px;
}
.verify-investor-photoId .verifybtn {
  margin-top: 15px;
  width: 57%;
}

@media screen and (max-width: 1199px) {
  .verify-investor-photoId .verifybtn {
    width: 100%;
  }
}
@media screen and (max-width: 771px) {
  .verify-investor-main-details {
    flex-direction: column;
  }
  .all-main-details {
    width: 90%;
  }
  .verify-investor-photoId {
    width: 90%;
    margin-top: 30px;
  }
  .verify-investor-photoId .verifybtn {
    width: 100%;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 550px) {
  .verify-investor-head-img figure {
    width: 76px;
  }
}
