.zr-nav {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.zr-nav a {
  font-size: 17px;
  color: #9f9f9f;
  text-decoration: none;
}

.zr-nav a:hover {
  text-decoration: underline;
}

.zr-nav span {
  color: #9f9f9f;
  font-size: 22px;
  padding: 0 9px;
}

.main-card-div {
  width: 100%;
  color: #9f9f9f;
}

.founderCard {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.main-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* box-shadow: 0 0 3px #4ad7d1; */
}

.main-logo figure {
  width: 150px;
  border-radius: 50%;
  padding: 3px;
  /* box-shadow: 0 0 3px turquoise; */
  margin-bottom: 4px;
}

.main-logo figure img {
  /* border-radius: 50%; */
  /* border: 1px solid #4ad7d1; */
}

.main-logo figcaption {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.main-logo figcaption p {
  margin: 0;
}

.main-logo p.companyName {
  font-size: 20px;
}

.main-logo p.founderName {
  font-size: 16px;
}

.main-logo p.location {
  font-size: 14px;
}

.logo-div {
  width: 24%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #9f9f9f;
  border-radius: 10px;
  margin-bottom: 20px;
}

.cards-names {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
}
.companyCard {
  border: 2px solid white;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
}
.companyCard .MuiAvatar-root {
  text-align: center;
  margin: auto;
}

.cards-names p {
  margin: 0;
}

.cards-names p.companyName {
  font-size: 20px;
  font-weight: bolder;
}

.cards-names p.founderName {
  font-weight: 600;
  font-size: 14px;
}

.cards-names p.location {
  font-weight: 400;
  font-size: 12px;
}

.viewBtn {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.black_CTA_view {
  border: 1px solid #4ad7d1;
  font-size: 15px;
  border-radius: 5px;
  font-family: Nunito;
  text-align: center;
  color: white;
  background: transparent;
  padding: 7px 20px;
}

.black_CTA_view:hover {
  color: #4ad7d1;
}

@media screen and (max-width: 1199px) {
  .logo-div {
    width: 30%;
    margin: 0 17px 20px 0;
  }

  .founderCard {
    justify-content: start;
  }
}

@media screen and (max-width: 988px) {
  .main-card-div {
    width: 110%;
  }

  .chips {
    padding: 0 !important;
  }

  .chipicon {
    font-size: 10px !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 770px) {
  .main-card-div {
    width: 100%;
    margin-top: 20px;
  }

  .logo-div {
    width: 46%;
    margin: 0;
    margin-bottom: 20px;
  }

  .founderCard {
    justify-content: space-around;
  }
}

@media screen and (max-width: 415px) {
  .zr-nav a {
    font-size: 13px;
  }

  .main-card-div {
    margin-top: 30px;
  }

  .logo-div {
    width: 75%;
    margin: 0;
    margin-bottom: 20px;
  }

  .founderCard {
    justify-content: center;
  }
}

.startup_register-root {
  background-color: #f5f5f5;
}

.startup_register-left-root {
  padding: 32px;
  min-width: 280px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(111, 111, 111, 0.05);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.startup_register-left-line {
  height: 32px;
  width: 1px;
  background-color: rgba(175, 175, 175, 0.5);
  margin-left: 19px;
}

.startup_register-left-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.startup_register-linactive-icon {
  background: #ffffff;
  border: 1px solid #9f9f9f;
}

.startup_register-lactive-icon {
  background: #1b1f1f;
  border: 1px solid #1b1f1f;
}

.startup_register-lactive-icon > svg > path {
  fill: white;
}

.startup_register-left-list {
  display: flex;
  align-items: center;
}

.startup_register-right-main {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(111, 111, 111, 0.05);
  border-radius: 12px;
  padding: 32px 24px;
  width: 100%;
}

.startup_register-logo-main {
  background: #ffffff;
  border: 0.5px solid #cacaca;
  border-radius: 50%;
  width: 96px;
  height: 96px;
}

.startup_register-logo-main > label {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.startup_register-logo-main > input[type="file"] {
  display: none;
}

.startup_register-logo-up,
.startup_register-logo-down {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.startup_register-logo-down {
  background: #d9d9d9;
}

.startup_register-logo-root {
  display: flex;
  align-items: center;
}

.startup_register-form-div input,
.startup_register-form-div textarea,
.startup_register-form-div select {
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 4px;
  height: 35px;
  padding: 0 16px;
  outline: none;
}

.startup_register-form-div textarea {
  height: 125px;
  padding: 8px 16px;
}

.startup_register-phoneNo input {
  border: none !important;
}

.startup_register-phoneNo
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #cacaca;
}

.startup_register-phoneNo .MuiOutlinedInput-notchedOutline {
  border-color: #cacaca;
}

.startup_register-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.startup_register-main ::-webkit-scrollbar {
  width: 0.5px;
}

.startup_register-right-root {
  height: 81vh;
  overflow-y: auto;
  padding-right: 10px;
  overflow-x: hidden;
}

.startup_register-doc-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 4px;
  border-bottom: 1px solid #cacaca;
}

.startup_register-doc-span {
  margin-right: 8px;
  margin-left: 8px;
  height: 6px;
  width: 6px;
  background-color: #000;
  border-radius: 50%;
}

.startup_register-logo-rmv {
  position: absolute;
  bottom: -50%;
  height: 48px;
  width: 100%;
  background-color: #cacacade;
  transition: all 0.8s 0.8s ease-in-out;
  animation: btu 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.startup_register-logo-main:hover .startup_register-logo-rmv {
  transition: all 0.8s ease-in-out;
  top: 50%;
}

.startup_register-form-div .MuiFormControl-root {
  border: 1px solid #cacaca;
  padding-right: 10px;
  border-radius: 4px;
}

.startup_register-form-div .MuiInput-input {
  border: none !important;
}

.startup_register-form-div .MuiFormControl-root:active {
  border: 1px solid #000;
}

.startup_register-form-div .MuiInput-root:before,
.startup_register-form-div .MuiInput-root:after,
.startup_register-form-div .MuiInput-root:hover {
  border: none !important;
}

/* responsive */
@media (max-width: 992px) {
  .startup_register-main {
    flex-direction: column;
  }

  .startup_register-left-root {
    flex-direction: row;
    width: 92%;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 24px;
  }

  .startup_register-left-line {
    height: 1px;
    width: 32px;
    background-color: rgba(175, 175, 175, 0.5);
    margin-left: 10px;
    margin-right: 10px;
  }

  .startup_register-right-root {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .startup_register-left-root {
    align-items: flex-start;
    padding: 20px;
  }

  .startup_register-left-list {
    flex-direction: column;
  }

  .startup_register-left-list > p {
    margin-top: 10px;
    text-align: center;
  }

  .startup_register-left-line {
    margin-top: 20px;
  }

  .startup_register-basic-details-root {
    width: 93%;
  }
}

@media (max-width: 576px) {
  .startup_register-left-root {
    width: 96%;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin: auto;
    margin-bottom: 24px;
  }

  .startup_register-left-list > p {
    display: none;
  }

  .startup_register-left-line {
    margin-top: 0px;
    width: 100%;
  }

  .startup_register-basic-details-root {
    width: 95%;
    margin: auto;
  }

  .startup_register-logo-root {
    flex-direction: column;
    align-items: flex-start;
  }
}
