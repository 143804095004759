.response-section{
    border-radius: 5px;
    border:1px solid #f2f2f2;
    margin:30px;
    text-align: left;
}
.response-section h4{
    padding: 10px;
    font-family: Spartan;
    font-weight: 600;
}
.response-pair{
    border-bottom: 2px solid #7f7f7f;
    margin:10px;
}